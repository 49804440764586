<template>
  <div style="height: 100%;background: #ebedf0;">
    <div class="biao_ti">
      <van-icon @click="back" class="fanhui" name="arrow-left" />
      <span>优惠券(共{{ menlist.length }}个门店)</span>
      <!-- <div class="dai" @click="tolinqu">
        <img class="quan_img" src="../../assets/img/quan.png" alt />
        <div class="dai_a">待领取</div>
        <div class="dian" v-if="haveGet"></div>
      </div>-->
    </div>
    <div class="hed_men">
      <div class="men_lis" v-for="(item, idx) in menlist" :key="idx" @click="tohmendian(item, idx)"
        :class="menidx == idx ? 'gaoliang' : ''">{{ item.garageName }}</div>
    </div>
    <div class="hed_lis">
      <div class="hhcc" @click="qiehuan(0)">
        未使用({{ list.length }})
        <div class="hua" v-if="active == '0'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(1)">
        已使用({{ listb.length }})
        <div class="hua" v-if="active == '1'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(2)">
        已过期({{ listc.length }})
        <div class="hua" v-if="active == '2'"></div>
      </div>
      <div class="bianmen" @click="tobiangen">变更门店></div>
    </div>
    <div style="padding-top:140px;position: relative;background: #ebedf0;">
      <div class="shanpin_lis">
        <div class="list_piao" v-for="(item, index) in active == 0 ? list : active == 1 ? listb : listc" :key="index"
          @click="tixiangqin(item)">
          <div style="overflow: hidden;">
            <div class="left c_h1">
              <div class="shuoming">
                <span class="xianshang" v-if="item.givenCoupon == 1">转赠券</span>
                <span class="shiyong">{{ item.couponName }}</span>
                <!-- <span class="shiyong" v-if="item.type == 2">折扣券</span>
                <span class="shiyong" v-if="item.type == 3">免费券</span>-->
              </div>
              <div class="shijian_yhq">{{ item.startDate }}--{{ item.validityDate }}</div>
              <div class="xianxi" v-if="item.status == 2">
                <span style="vertical-align: middle">查看详情</span>
              </div>
            </div>
            <div class="right c_tup" @click.stop="totiaozhuan(item)">
              <div class="ccdingwei">
                <div class="cjiage">
                  <span v-if="item.type == 1">
                    <span class="dan">￥</span>
                    <span>{{ item.discountPrice }}</span>
                  </span>
                  <span v-if="item.type == 2">
                    {{ item.discountPrice }}
                    <span class="dan">折</span>
                  </span>
                  <span v-if="item.type == 3">免费券</span>
                  <span v-if="item.type == 4">免费券</span>
                  <span v-if="item.type == 5">
                    <span class="dan">￥</span>
                    <span>{{ item.discountPrice }}</span>
                  </span>
                  <span v-if="item.type == 6 || item.type == 7">
                    <span class="dan">￥</span>
                    <span>{{ item.discountPrice }}</span>
                  </span>
                </div>
                <div class="sm">
                  <span v-if="item.type == 1">满{{ item.useCill }}元可用</span>
                  <span v-if="item.type == 2 && item.useCill > 0">{{ item.useCill }}可用</span>
                  <span v-if="item.type == 3">{{ item.useCill }}可用</span>
                  <span v-if="item.type == 5">单次券</span>
                  <span v-if="item.type == 6">礼包券</span>
                  <span v-if="item.type == 7">储值卡券</span>
                </div>
                <div class="lijis" v-if="item.type == 6">
                  <span v-if="item.useScope == 2" style="color:#c1bc3c">立即兑换</span>
                  <span v-else style="color: #1691b3;">立即兑换</span>
                </div>
                <div class="lijis" v-else>
                  <span v-if="item.useScope == 2" style="color:#c1bc3c">立即使用</span>
                  <span v-else style="color: #1691b3;">立即使用</span>
                </div>
              </div>

              <img class="shangping" v-if="item.useScope == 2" src="../../assets/img/youhui2.png" alt />

              <img class="shangping" v-else src="../../assets/img/youhui.png" alt />
            </div>
          </div>
          <div class="xianqin" v-show="item.checked">
            <div>
              <span>说明：{{ item.useExplain }}</span>
            </div>
            <div>
              <span>券编号：{{ item.couponNumber }}</span>
              <span class="right" v-if="item.number" @click.stop="toxiangqin(item)">
                订单号：
                <span style="color: #32CD32;text-decoration:underline">{{ item.number }}</span>
              </span>
            </div>
          </div>
          <img v-if="active == 1 && item.status == 3" class="yishiyong" src="../../assets/img/yishiyong.png" alt />
          <img v-if="active == 1 && item.status == 4" class="yishiyong" src="../../assets/img/yizz.png" alt />
          <img v-if="active == 2" class="yishiyong" src="../../assets/img/yiguoqi.png" alt />
        </div>
      </div>

      <div v-for="(item, idx) in listxiu" :key="idx" class="card">
        <div v-if="item.type == 0">
          <van-row type="flex" align="center">
            <van-col span="12" class="tops">
              <div class="middles">
                <img src="../../assets/img/logos.png" width="35" />
              </div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="24" class="imgs">
              <div class="coupon_img">
                <span class="vadta">{{ item.validDate }}</span>
                <van-image :src="img" />
              </div>
            </van-col>
          </van-row>
        </div>
        <div v-else>
          <van-row type="flex" align="center">
            <van-col span="12" class="tops">
              <div class="middles">
                <img src="../../assets/img/logos.png" width="35" />
              </div>
            </van-col>
            <van-col span="12" class="tops" @click="shuoming(item)">
              <div class="middles">
                <van-icon name="info" size="25" />
                <div class="middles">活动说明</div>
              </div>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="24" class="imgs">
              <div class="coupon_img">
                <div class="name_h1">{{ item.activityName }}</div>
                <div class="name_h2">{{ item.garageName }}</div>
                <div class="name_h3">有效期：{{ item.activityStartDate ? item.activityStartDate.substring(0, 10) : '' }} 至
                  {{ item.activityEndDate ? item.activityEndDate.substring(0, 10) : '' }}</div>
                <span class="huo_name">活动券</span>
                <van-image class="imgesc" style="height: 133px;" :src="img2" />
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
      <van-empty v-if="list.length < 1 && listxiu.length < 1" description="暂无数据~" />
    </div>
    <van-overlay :show="isshow">
      <div class="biao_ti">
        <van-icon @click="isshow = false" class="fanhui" name="arrow-left" />
        <span>活动说明</span>
      </div>
      <div class="bode">
        <div class="hed_h1">
          <img class="imgsd" src="../../assets/img/logos.png" width="35" />
          <div class="name_dd">{{ list_body.activityName }}</div>
        </div>
        <div class="list_and" style="margin-top: 20px;">活动门店：{{ list_body.garageName }}</div>
        <div class="list_and">活动时间：2020-10-22至2020-10-31</div>
        <div class="list_and">优惠券售价：{{ list_body.activityPrice }}元</div>
        <div class="list_and nede">
          活动说明：(
          <span v-for="items in list_body.activityItems" :key="items">
            {{ items.itemName }}{{ items.itemCounts }}{{ items.unit }}
            <span>,</span>
          </span>)
        </div>
        <van-row>
          <van-col span="24" class="imgs"></van-col>
        </van-row>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import userModel from "../../api/user";
import { cameraTest } from "../../api/check";
import { Dialog } from "vant";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  // mixins: [preventBack], //注入
  components: {},
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      img: require("../../assets/img/coupon.png"),
      img2: require("../../assets/img/beijin.jpg"),
      forlist: [
        { name: "全部", nuber: "0" },
        { name: "洗车", nuber: "0" },
        { name: "打蜡", nuber: "0" },
        { name: "内饰洗车", nuber: "0" },
        { name: "杀菌消毒", nuber: "0" },
        { name: "补胎", nuber: "0" }
      ],
      list: [],
      listb: [],
      listc: [],
      menlist: [],
      checked: false,
      shopisok: false,
      show: true,
      menidx: 0,
      isshow: false,
      haveGet: null,
      list_body: {},
      listxiu: [],
      active: 0,
      isActive: 1
    };
  },
  methods: {
    shuoming(item) {
      this.list_body = item;
      this.isshow = true;
    },
    //变更套餐
    tobiangen() {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false,
          title: "请选择变更后的门店",
          showisok: "youhui_list",
          ogid: this.menlist[this.menidx].gid
        }
      });
    },
        getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    },
    tosoayisao(ids) {
      var _this = this;
      // var search = "?couponNumber=3680189628&sellRule=1";
      if (
        _this.chexin == null ||
        _this.chexin.carNo == "" ||
        _this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再使用扫码功能！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          console.log(res);
          // alert(res.resultStr)
          // _this.result = res.resultStr;
          var search = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          var no = _this.getSearchString("no", search); //结果：18
          var sellRule = _this.getSearchString("sellRule", search); //结果：18
          var number = _this.getSearchString("number", search); //结果：18
          var open = _this.getSearchString("open", search); //结果：18


          if (sellRule == 1) {
            var data = {
              number: number,
              type: 0,//0检查1开始2关闭/3定时
              no: no,
            };
            cameraTest(data).then(e => {
              if (e.code == 200) {
                var message = ''
                if (e.data == 0) {
                  message = '是否启动当前按摩椅？'
                } else {
                  message = '按摩椅正在使用，是否从新启动当前按摩椅？'
                }
                Dialog.alert({
                  title: '提示',
                  message: message,
                  theme: 'round-button',
                  closeOnClickOverlay: true,
                }).then(() => {
                  var datas = {
                    number: number,
                    type: 3,//0检查1开始2关闭/3定时
                    no: open,
                    time: 900,
                    ids: ids,
                  };
                  cameraTest(datas).then(e => {
                    if (e.code == 200) {
                      _this.$toast.success("启动成功！");
                      _this.onLoad()
                    }
                  });
                });


              }
            });


          }



        }
      });
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("gid")
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["scanQRCode"]
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    //跳转优惠券详情
    tixiangqin(item) {
      if (this.active != "0") {
        return;
      }
      sessionStorage.setItem("couponitem", JSON.stringify(item));
      this.$router.push({
        path: "tuoke_coupon_details",
        query: {
          couponNumber: item.couponNumber,
          couponName: item.couponName,
          useExplain: item.useExplain,
          validity: item.validity,
          startDate: item.startDate,
          validityDate: item.validityDate,
          sellPrice: item.sellPrice,
          givenCoupon: item.givenCoupon,
          id: item.id,
          type: item.type,
          sellRule: 1, //1：转增 2.出售
          uid: this.userInfo.id,
          isok: true
        }
      });
    },

    //跳转详情
    toxiangqin(item) {
      if (item.useScope == 2) {
        this.$router.push({
          path: "consume_details",
          query: {
            id: item.number
          }
        });
      }
    },
    init() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      orderModel
        .getMyActivityTakeRecord()
        .then(e => {
          // loading.clear();
          this.listxiu = e.data;
        })
        .catch(() => loading.clear());
    },

    dianji() {
      this.checked = !this.checked;
    },
    qiehuan(row) {
      this.active = row;
      // this.onLoad();
    },
    zhankai(item) {
      console.log(item);
      item.checked = !item.checked;
    },
    //切换门店
    tohmendian(item, idx) {
      this.menidx = idx;
      this.active = 0;
      this.list = this.menlist[this.menidx].getList;
      this.listb = this.menlist[this.menidx].useList;
      this.listc = this.menlist[this.menidx].overdueList;
      this.haveGet = this.menlist[this.menidx].haveGet;
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        // customerId: 223
        customerId: this.userInfo.customerId
      };

      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      orderModel
        .myCouponnew(data)
        .then(e => {
          console.log(e.data);
          this.menlist = e.data;
          this.list = this.menlist[this.menidx].getList;
          this.listb = this.menlist[this.menidx].useList;
          this.listc = this.menlist[this.menidx].overdueList;
          this.haveGet = this.menlist[this.menidx].haveGet;
          // loading.clear();
          // this.list = e.data.getList;
          // this.listb = e.data.useList;
          // this.listc = e.data.overdueList;
          // this.haveGet = e.data.haveGet;
          // console.log(this.list);
        })
        .catch(() => loading.clear());
    },

    back() {
      if (this.shopisok == true) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: "user"
        });
      }

    },
    //使用优惠券
    totiaozhuan(item) {
      var _this = this;
      var time = new Date(item.startDate).getTime();
      var timedata = new Date().getTime();
      if (timedata < time) {
        this.$toast("该优惠券未到使用时间！");
        return
      }

      if (1631318400000)
        if (item.status != 2) {
          return;
        }
      if (
        this.chexin == null ||
        this.chexin.carNo == "" ||
        this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再使用！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
      if (item.type == 6 || item.type == 7) {
        Dialog.confirm({
          title: "提示",
          message: "是否兑换优惠券！"
        })
          .then(() => {
            var data = {
              id: item.id,
              gid: _this.shangjia.id,
              carNo: _this.chexin.carNo
            };
            orderModel
              .couponuse(data)
              .then(e => {
                if (item.type == 6) {
                  _this.$toast("兑换礼包券成功！");
                } else {
                  _this.$toast("兑换储值卡成功！");
                }

                _this.onLoad();
                _this.init();
              })
              .catch(() => loading.clear());
          })
          .catch(() => {
            // on cancel
          });
        return
      } else if (item.type == 5) {
        console.log('sadasdsad')
        if (item.massage) {
          _this.tosoayisao(item.id)
          return

        } else {
          window.location.href =
            "/order_yuyue?orderNumber=" +
            item.couponNumber +
            "&carNo=" +
            this.chexin.carNo +
            "&type=" +
            3 +
            "&rowtype=" +
            3 +
            "&applyItem=" +
            item.applyItem +
            "&couponId=" +
            item.id +
            "&appointmentId=" +
            item.appointmentId;
        }

        return
      } else if (item.type == 3) {
        window.location.href =
          "/order_yuyue?orderNumber=" +
          item.couponNumber +
          "&carNo=" +
          this.chexin.carNo +
          "&type=" +
          4 +
          "&rowtype=" +
          4 +
          "&applyItem=" +
          item.applyItem +
          "&couponId=" +
          item.id +
          "&appointmentId=" +
          item.appointmentId +
          "&navigationId=" +
          item.navigationId +
          "&navigationType=" +
          item.navigationType;;
        return
      }
      if (item.navigationType == 0) {
        this.$router.push({
          path: "list",
          query: {
            Aswitch: 1
          }
        });
      } else if (item.navigationType == 1) {
        this.$router.push({
          path: "maintain",
          query: {
            Aswitch: 1,
            name: item.applyItem
          }
        });
      } else if (item.navigationType == 2) {
        this.$router.push({
          path: "list_details_xiche",
          query: {
            id: item.navigationId
          }
        });
      } else if (item.navigationType == 3) {
        this.$router.push({
          path: "spray_lacquer",
          query: {
            Aswitch: 1
          }
        });
      }
      // this.$router.push({
      //   path: "user"
      //   // query: {}
      // });
    },
    tolinqu() {
      sessionStorage.removeItem("to");
      this.$router.push({
        path: "youhui_linqu"
        // query: {}
      });
    }
  },
  created() {
    this.shopisok = this.$route.query.shopisok
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
          return
        })
        .catch(() => loading.clear());
      return
    }
    this.onLoad();
    this.init();
    this.getConfig()
    // console.log(new Date('2021-09-10').getTime())
    // console.log(new Date('2021-09-11').getTime())
  },
  mounted() { }
};
</script>
<style >
.maintain .van-ellipsis {
  color: #fff !important;
}

.maintain .van-icon {
  color: #fff !important;
}

.maintain>.van-hairline--bottom::after {
  border: none !important;
}
</style>
<style lang="less" scoped>
body {
  box-sizing: border-box;
}

.xianshang {
  float: left;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  padding: 1px 8px;
  margin-top: 2px;
  line-height: 16px;
  color: red;
  font-weight: 400;
  border: 1px solid red;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.xianshangb {
  display: inline-block;
  color: #fff;
  background: linear-gradient(to right, #daa520, #b8860b);
  border-radius: 5px;
  font-size: 12px;
  padding: 2px 12px;
  line-height: 15px;
  transform: scale(0.83);
  overflow: hidden;
  margin-bottom: 3px;
}

.shuoming {
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;
}

.shiyong {
  margin-left: 6px;
  width: 160px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// 洗车
.list_piao {
  overflow: hidden;
  margin: 5px 12px;
  font-size: 14px;

  background: #fff;
  position: relative;
}

.c_h1 {
  padding: 8px 0px 0px 10px;
}

.shijian_yhq {
  margin-top: 16px;
  font-size: 12px;
}

.xianxi {
  color: #666;
  font-size: 12px;
}

.shou_s {
  color: #666;
}

.c_tup {
  overflow: hidden;
  position: relative;
}

.xianqin {
  font-size: 12px;
  padding: 5px 15px;

  border-top: 1px solid #f5f5f5;
}

.xianqin div {
  line-height: 20px;
  color: #999;
}

.xianqin div span {
  display: inline-block;
}

.quan_img {
  height: 15px;
  width: 22px;
  display: block;
  margin: auto;
}

.dai_a {
  font-size: 12px;
  line-height: 16px;
  transform: scale(0.8);
  position: relative;

  color: #f1f1f1;
}

.dian {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 4px;
  background: #ffff;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  z-index: 8;
}

.dai {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 12px;
  margin-right: 20px;
  padding: 2px 0 0 0;
}

.sm {
  // position: absolute;
  // left: 0;
  // top: 0;
  min-height: 20px;
  width: 100%;
  text-align: center;
  // margin-top: 40px;
  color: #fff;
  font-size: 10px;
  transform: scale(0.83);
}

.lijis {
  font-size: 12px;
  border-radius: 8px;
  height: 16px;
  background: #fff;
  width: 70px;
  margin: auto;
  color: #1691b3;
  line-height: 16px;
  text-align: center;
  transform: scale(0.83);
}

.dan {
  font-size: 16px;
}

.ccdingwei {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.cjiage {
  color: #fff;
  font-weight: bold;
  font-size: 26px;
  // width: 100%;

  text-align: center;

  margin-top: 15px;
}

.xianxia {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  text-align: center;
  margin-left: 60px;
}

.shangping {
  width: 110px;
  height: 100px;
  display: block;
}

// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}

.zaixian {
  font-size: 12px;
  line-height: 12px;
}

.bott_a {
  text-align: center;
  margin-top: 5px;
}

.bott_c {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  font-weight: bold;
  padding: 7px 18px;
  border-radius: 19px;
}

.heji {
  font-weight: bold;
}

.danwei {
  font-size: 10px;
  color: #d9314c;
}

.monede {
  font-size: 18px;
  font-weight: bold;
  color: #d9314c;
}

.zhe {
  color: #666;
  margin-left: 5px;
}

.bott_b {
  font-size: 13px;
  margin-right: 10px;
  margin-top: 5px;
}

.anzhuan {
  font-size: 12px;
  color: #666;
  line-height: 12px;
  text-align: right;
}

.kefu {
  width: 25px;
  height: 25px;
}

// 底部
// 标题
.biao_ti {
  height: 46px;
  background: #df3447;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  text-align: center;
  color: #fff;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.fanhui {
  line-height: 46px;
  padding: 0 10px;
  position: absolute;
  left: 0;
  top: 0;
}

//标题

.van-nav-bar {
  background: #df3447;
  color: #fff;
}

.shanpin_lis {
  position: relative;
  // background: #fff;

  overflow: hidden;
}

.shanb_biao {
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 0;
  margin-bottom: 10px;
  background: #fff;
  //   transition: all .8s
}

.shanb_h1 {
  overflow: hidden;
  line-height: 20px;
}

.jinji {
  font-size: 9px;
  color: #fff;
  display: inline-block;
  margin-left: 34px;
  background: #d9314c;
  padding: 0 3px;
  border-radius: 3px;
  line-height: 12px;
}

.shanb_h2 {
  font-size: 12px;
  color: #666;
  margin-left: 34px;
  line-height: 16px;
}

.chek {
  font-weight: bold;
  margin-left: 10px;
}

.jiner {
  color: #666;
  margin-right: 10px;
}

.list_fuwu {
  overflow: hidden;
  position: relative;
}

.mian_a {
  width: 70px;
  margin-left: 40px;
}

.order_img {
  height: 70px;
  width: 70px;
}

.mian_b {
  font-size: 14px;
  margin-left: 15px;
  width: 215px;
}

.lis_ha {
  font-size: 12px;
  line-height: 20px;
}

.lis_hb {
  font-size: 10px;
  line-height: 12px;
  margin-top: 6px;
}

.lis_hc {
  margin-top: 5px;
}

.lis_hb span {
  display: inline-block;
  color: #9370db;
  border: 0.5px solid #9370db;
  line-height: 12px;
  margin-left: 5px;
  border-radius: 3px;
  padding: 0 2px;
}

.jiner_c {
  color: #d9314c;
}

.hed_men {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
  white-space: nowrap;
  overflow-x: scroll;
}

.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 91px;
  width: 100%;
  z-index: 99;
}

.men_lis {
  border: 2px solid #d1d1d1;
  font-size: 13px;
  height: 27px;
  color: #333;
  line-height: 27px;
  border-radius: 6px;
  min-width: 40%;
  // minwidth: 40%;
  padding: 0 5px;
  margin: 7px 3px 0 3px;
  display: inline-block;
  text-align: center;
  box-sizing: content-box;
  overflow: hidden;
}

.gaoliang {
  border: 2px solid #df3447;
  color: #df3447;
}

.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 25%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}

.bianmen {
  float: right;
  font-size: 14px;
  line-height: 45px;
  color: #1164b6;
  margin-right: 10px;
}

.hua {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 5px;
  width: 46px;
  text-align: center;
  margin-left: -23px;
  height: 3px;
  z-index: 1;
  background-color: #ee0a24;
  border-radius: 1.5px;
}

.clear {
  clear: both;
  height: 55px;
}

.isActive {
  border: 0.5px solid #a50e43;
  color: #a50e43;
  background: #f1dadc;
}

.list_piao:nth-of-type(n + 2) {
  margin-top: 10px;
}

//分享裂变
.bode {
  background: #fff;
  border-radius: 20px;
  height: 90%;
  margin: 10px;
  padding: 10px;
  margin-top: 56px;
}

.name_dd {
  text-align: center;
  height: 49px;
  line-height: 49px;
  color: #000;
  font-size: 16px;
}

.imgsd {
  position: absolute;
  left: 0;
  top: 0;
}

.hed_h1 {
  position: relative;
  border-bottom: 1px solid #eee;
  padding-bottom: 12px;
}

.yishiyong {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 90px;
  margin-top: 10px;
  width: 80px;
  z-index: 99;
}

.card {
  background: #fff;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
}

.list_and {
  font-size: 14px;
  line-height: 26px;
  color: #333;
}

.card .tops {
  padding-bottom: 5px;
}

.card .tops:nth-of-type(2) {
  text-align: right;
}

.card .tops:nth-of-type(2) .middles {
  color: #0d906d;
  font-size: 14px;
}

.card .middles {
  display: inline-block;
  vertical-align: middle;
}

.card .middles:nth-of-type(2) {
  padding-left: 5px;
  color: #000;
  font-size: 14px;
}

.card .imgs {
  border-top: 1px solid #eee;
  padding: 10px;
}

.wrapper {
  padding: 50px 20px;
  height: 100%;
}

.wrapper p {
  color: #fff;
}

.coupon_img {
  position: relative;
}

.huo_name {
  position: absolute;
  top: 0px;
  color: #fff;
  font-weight: bold;
  z-index: 9;
  font-size: 12px;
  right: 5px;
}

.van-overlay {
  z-index: 99999;
  background: rgb(242, 242, 242);
}

.name_h1 {
  position: absolute;
  width: 70%;
  left: 50%;
  margin-left: -35%;
  top: 26%;
  color: #fff;
  font-weight: bold;
  z-index: 9;
  font-size: 23px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nede span:last-child span {
  display: none;
}

.name_h2 {
  position: absolute;
  width: 60%;
  left: 50%;
  margin-left: -30%;
  top: 45%;
  color: #fff;
  z-index: 9;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.van-icon {
  vertical-align: middle;
}

.name_h3 {
  position: absolute;
  width: 100%;
  /* width: 60%; */
  left: 50%;
  margin-left: -50%;
  top: 61%;
  color: #fff;
  z-index: 9;
  font-size: 11px;
  text-align: center;
}

.vadta {
  position: absolute;
  bottom: 9%;
  z-index: 9;
  left: 35%;
  font-size: 11px;
}
</style>